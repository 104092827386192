@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

body{
  /* text-align:center; */
  margin:0;
  transition: filter 0.5s ease; 
  font-family: 'Lato', sans-serif;
  letter-spacing: .4em;
}

.header{
  position: fixed;
  top: 0;
  left: 0;
  z-Index: 99;
  display:flex;
  background: black;
  width: 100%;
}
.mh{
  display: none;
}

.header h1{
color: #ccc;
margin:20px ;
letter-spacing: .2em;
font-weight: 600;
font-size:1.3em;
font-family: 'Lato', sans-serif;
}

.header a{
  text-decoration: none;
}

.header-links{
  color:#ccc;
  letter-spacing: .4em;
  font-weight: 600;
  text-decoration:none;
  font-size:.7em;
}

.menuanime {
  opacity: 0;
  animation: 1s appear forwards;
}

.menuItem{
  font-Family:'Open Sans', sans-serif;
  font-Size: 1.2rem;
  padding: 4rem 0 1rem;
  margin: 0 5%;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  animation: 0.5s slideIn forwards;
}

.insideline {
  width: 90%;
  height: 1px;
  /* background: #ccc; */
  margin: 0 auto;
  animation: 0.5s shrink forwards;
  
}

.rmh .header-links{
  display:none;
}

.menudropdown{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-Direction:column;
  background-color: rgba(0,0, 0, .9);
  /* color: white; */
  transition: height 0.3s ease;
  z-index: 2;
}

.menuList{
  padding-Top: 3rem;
  text-align: center;
}




@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

@media screen and (min-width: 1040px) {

  
  body{
    margin-top:0;
  }

  .header{
    display:none;
  }

  .mainheader{
    display: inline-block;
    width:100%;
    height:auto;
    background-color:black;
    opacity: 0.9;
    position: fixed;
    padding: 5px 20px;
    z-index: 999999999999;
  }
  .mh{
    color: lightgray;
    margin:10px 20px;
    letter-spacing: .29em;
    font-weight: 600;
    font-size:1.3em;
    font-family:'Lato', sans-serif;
    display: inline;
    float:left;
    }

    .rmh{
      padding:12px 20px;
      float:right;
      
    }

    .rmh .header-links{
      display: inline;
      padding: 0 25px;
      color:#ccc;
      text-decoration: none;
    }

}