.modal-wrapper {
    background:black;
    margin: 10% auto;
    transition: all .8s;
    width: 100%;
    left:0;
    position: absolute;
    z-index: 88;
}

/* .modal-header {
    background: #263238;
    height: 40px;
    line-height: 40px;
    padding: 5px 20px;
    text-align: right;
} */

/* .modal-header h3 {
    color: white;
    float: left;
    margin: 0;
    padding: 0;
} */

.modal-body {
    width: 70%;
    margin:0 auto;
    padding: 10px 15px;
    text-align: center;
}

.modal-footer {
    background: black;
    height: 35px;
    padding: 45px;
    border-top: none;
}

.close-modal-btn {
    color: white;
    cursor: pointer;
    float: right;
    font-size: 30px;
    margin: 0;
}

.close-modal-btn:hover {
    color: black;
}

.btn-cancel {
    background:#9c1491;
    border: none;
    color: white;
    cursor: pointer;
    outline: none;
    padding: 3px 25px;
    margin:40px;
    float: left;
    letter-spacing: .3em;
    font-size:.8em;
}

.btn-cancel:hover{
    background:white;
    color:black;
    transition:2s;
}

.btn-continue {
    background-color: #1b5e20;
    float: right;
}

.back-drop {
    background-color: rgba(48, 49, 48, 0.42);
    height: 100%;
    position: fixed;
    transition: all 1.3s;
    width: 100%;
}

.open-modal-btn {
    color:#ccc;
    letter-spacing: .4em;
    font-weight: 600;
    text-decoration:none;
    font-size:.7em;
    background:transparent;
    border:none;
}

*:focus {
    outline: 0 !important;
}

@media only screen and (max-width: 700px){

    .modal-body{
        width: 100%;
    }

    .btn-cancel{
        margin:0;
    }
}