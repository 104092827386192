
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

html, body{
    max-width:100%;
    height: 100%;
    background-color:black;
}

.all-bod{
    width: 100%;
    height: 100vh;  
    
}
.landing-bod{
    background-image:url('https://images.pexels.com/photos/1074882/pexels-photo-1074882.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
    background-size:cover;
    width: 100%;
    height: 100vh;
    padding-top:8em;
    position: relative;
}


.parallax{
    background-image: url('/images/pink.jpg');
    background-size:cover;
    width: 100%;
    background-attachment: fixed;
    padding: 1.1em 0;
}

.parallax2{

    background-image: url('/images/pink.jpg');
    background-size:cover;
    width: 50%;
    margin: 5em auto;
    background-attachment: fixed;
    padding: .05em 0;
}

.social-wrapper{
    width: 50%;
    margin:0 auto;
}

#social{
    padding: 0px;
}

.smedia{
    width:45px;
    margin:20px auto;
    background-color:white;

}

.landing-bod2{
    background-size:cover;
    width: 100%;
    height: auto;
    background-attachment: fixed;
    background-color:black;
}

.landing-bod3{
    width: 100%;
    height: auto;
    background-color:black;
    padding: 0 3em;
}

.landing-bod3 h3{
        padding:13% 0 8%;
        text-align: center;
        font-weight: 600;
        color:#ccc;
        text-align: center;
        font-size:1em;   
}

.landing-bod3 img{
    width:100%;
}

.about-box{
    width:41%;
    height:100%;
    background-color:black;
    float:left;
}

.right{
    width: 57%;
    height:97%;
    background-color: black;
    float:right;
}

.all-bod h1{
    padding-top:4em;
}

.wrapper{
    text-align: center;
}

.mtop{
    width: 100%;
    margin-top:7%;
}

.wrapper h1{
    font-size: 7em;
    padding:58px 32px 72px;
    border:15px solid lightgray;
    background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif);
	background-size: cover;
    color: transparent;
    display: inline-block;
	-moz-background-clip: text;
    -webkit-background-clip: text;
    
}

.sub-tag{
    color:#ccc;
    text-align: center;
    letter-spacing: .5em;
}

.blue hr, .blue p, .blue2 hr, .blue2 p{
    display: none;
}

.test-scroll{
    margin-top:10%;
    padding:3%;
    font-weight: 600;
    color:#ccc;
    text-align: center;
    font-size:1em;
}

.b-border{
    width:100%;
    height: auto;
    padding:40px;
    color:#ccc;
    font-size:.7em;
    text-transform: uppercase;
    line-height: 1.8em;
    letter-spacing: .3em;
}

.b-border span{
    color:#f36aec;
}


.descrip h6{
 text-align: center;
 color:white;
 padding:20px 0 30px;
 text-transform: uppercase;
 font-size:.8rem;
}

.vsite{
    text-align:center;
    padding: 60px 0;
}

.vsite small{
    border: 1px solid white;
    padding:10px 40px;
    margin: 20px;

}

form{
    width:80%;
    margin:0 auto;
    color:white;
    height: 100%;

}

label {
	display:inline-block;
	padding: 1em 0em 1em;
    font-size: 1em;
    letter-spacing: .49em;
}

input{
    width: 100%;
    height: 2.5em;
    letter-spacing: .3em;
}

textarea{
    width: 100%;
    height: 5em;
    letter-spacing: .3em;

}

#submit{
	background-color:black;
	text-align: center;
    width: 230px;
    max-width: 300px;
    font-size: 1em;
    padding: 8px;
    border-radius:2px;
    letter-spacing: .49em;
    color:white;
    margin: 2em auto 0;
}

#submit:hover{
    background-color:#ccc;
    transition:2s;
    
}


.hover_image {
    position: relative;
    width: 100%;
  }
  .hover_image img {
    width: 100%;
    height: auto;
  }
  .hover_image figcaption {
    display: block;
    opacity: 0;
    transition: opacity 0.6s ease;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    color:white;
  }
  .hover_image figcaption .inner {
    text-align: center;
    padding: 2em;
    position: relative;
    top: 32%;
    transform: translateY(-50%);
  }
  .hover_image figcaption .inner h6 {
    padding-bottom:10px;
    transform: translateY(100%);
    opacity: 0;
  }
  .hover_image figcaption .inner .desc {
    font-size:.8em;
    letter-spacing: .2em;
    transform: translateY(100%);
    opacity: 0;
  }
  .hover_image figcaption:hover > .inner h6 {
    transform: translateY(0%);
    opacity: 1;
    transition: all 0.7s ease;
    color:plum;
  }
  .hover_image figcaption:hover > .inner .desc {
    transform: translateY(0%);
    opacity: 1;
    transition: all 0.9s ease;
  }
  .hover_image:hover > figcaption,
  .hover_image:focus > figcaption {
    opacity: 1;
  }
  

/* ----------------------------Mobile Size Begins------------------ */

@media only screen and (max-width: 700px){

    
    .all-bod{
        width: 100%;
        height: auto;  
        
    }
    .landing-bod{
        background-image:url('https://images.pexels.com/photos/1074882/pexels-photo-1074882.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
        background-size:cover;
        width: 100%;
        height: 100vh;
        padding-top:5em;
        position: relative;
    }
    
    h3{
        margin:30px;
    }
    
    .parallax{
        display: none;
    }
    
    .landing-bod2{
        background-size:cover;
        width: 100%;
        height: auto;
        background-attachment: fixed;
        background-color:black;
    }
    
    .landing-bod3{
        width: 100%;
        height: auto;
        background-color:black;
        padding: 0 3em;
    }
    
    .landing-bod3 h3{
            padding:0% 0 8%;
            text-align: center;
            font-weight: 600;
            color:#ccc;
            text-align: center;
            font-size:1em;
    }
    
    .landing-bod3 img{
        width:100%;
        padding:10px;
    }
    
    .about-box{
        width:41%;
        height:100%;
        background-color:black;
        float:left;
    }
    
    .right{
        width: 57%;
        height:97%;
        background-color: black;
        float:right;
    }
    
    .all-bod h1{
        padding-top:4em;
    }
    
    .wrapper{
        text-align: center;
    }
    
    .mtop{
        width: 100%;
        margin-top:7%;
    }
    
    .wrapper h1{
        font-size: 6em;
        padding:48px 32px 62px;
        border:13px solid lightgray;
        background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif);
        background-size: cover;
        color: transparent;
        -moz-background-clip: text;
        -webkit-background-clip: text;
        
    }
    
    .sub-tag{
        color:#ccc;
        text-align: center;
        letter-spacing: .5em;
    }
    
    .blue hr, .blue p, .blue2 hr, .blue2 p{
        display: none;
    }
    
    
    .test-scroll{
        margin-top:10%;
        padding:0%;
        font-weight: 600;
        color:#ccc;
        text-align: center;
        font-size:1em;
    }
    
    .b-border{
        width:100%;
        height: auto;
        padding:10px;
        color:#ccc;
        font-size:.7em;
    }
    
    
    .descrip h6{
     text-align: center;
     color:white;
     padding:10px 0;
    }
        
    .vsite{
        text-align:center;
        padding: 60px 0;
    }
    
    .vsite small{
        border: 1px solid white;
        padding:10px 40px;
        margin: 20px;  
    }
    
    form{
        width:80%;
        margin:0 auto;
        color:white;
        height: 100%;
    }
    
    label {
        display:inline-block;
        padding: 1em 0em 1em;
        font-size: 1em;
        letter-spacing: .49em;
    }
    
    input{
        width: 100%;
        height: 2.5em;
        letter-spacing: .3em;
    }
    
    textarea{
        width: 100%;
        height: 5em;
        letter-spacing: .3em;  
    }
    
    #submit{
        background-color:black;
        text-align: center;
        width: 230px;
        max-width: 300px;
        font-size: 1em;
        padding: 8px;
        border-radius:2px;
        letter-spacing: .49em;
        color:white;
        margin: 2em auto;
    }
    
    #submit:hover{
        background-color:#ccc;
        transition:2s;    
    }
    
    .smedia{
        width:35px;
    
    }

    .social-wrapper{
        width: 100%;
    }
}


/* ----------------------------Mobile Size Ends------------------ */


@media screen and (min-width: 1040px) {

    .all-bod{
        margin-top:0;
    }

    .blue hr, .blue p, .blue2 hr, .blue2 p{
        display: inline;
    }

.hr1{
    background-color:#ccc;
    width: 50%;
    float: left;
	-webkit-animation: scale-in-hor-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-hor-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes scale-in-hor-left {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes scale-in-hor-left {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }

  .hr2{
    background-color:#ccc;
    width: 50%;
    float: right;
    -webkit-animation: scale-in-hor-right 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-hor-right 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes scale-in-hor-right {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @keyframes scale-in-hor-right {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  
a{
    color:white;
}

a:hover{
    color:white;
    text-decoration: none;
}

.blue{
    padding: 4% 5px;  
}

.blue2{
    padding: 20% 5px ;
}

.blue2 p{
    writing-mode: vertical-rl;
    text-orientation: sideways-right;
    float:left;
    font-size:.7em;
    font-style: 'Lato';
    color:lightgray;
    margin-top:-15px;
}

.blue p{
    writing-mode: vertical-rl;
    text-orientation: sideways-right;
    float:right;
    font-size:.7em;
    font-style: 'Lato';
    color:lightgray;
    margin-top:-30px;
}
@-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }

  .landing-bod2{
      height: auto;
      padding:6em 0;
  }

  .test-scroll{
    margin-top:50%;
    text-align: right;
    font-size: 1.5em;
    font-weight: 900;
}

.landing-bod3 h3{
    padding-top:8%;
    font-size: 1.5em;
    font-weight: 900;
    text-align: right;
    padding-right:40px;
}

.b-border{
    width:100%;
    height: 28em;
    border-left:1px solid #666;
    padding:40px;
}
    
}

